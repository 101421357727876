import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  ScrollView,
  StatusBar,
  Text,
  VStack,
} from 'native-base'
import { useEffect, useState } from 'react'
import { Platform, StyleSheet, useWindowDimensions } from 'react-native'
import {
  ActivityRecommendationState,
  ActivityType,
} from '../../../domain/models/interfaces/activityInterfaces'
import analytics from '../../../domain/services/analytics/AnalyticsService'
import BugSnagService from '../../../domain/services/bugsnag/BugSnagService'
import clevertap from '../../../domain/services/clevertap/ClevertapAdapter'
import {
  useDesktopBreakpoint,
  useMobileBreakpoint,
} from '../../../domain/services/useBreakpoint'
import ActivityModalPreview from '../components/explore/activities/ActivityModalPreview'
import HomeDesktopView from '../components/home/HomeDesktopView'
import HomeImageBackground from '../components/home/HomeImageBackground'
import HomeMobileView from '../components/home/HomeMobileView'
import MinutesProgress from '../components/home/MinutesProgress'
import OasisSelect from '../components/home/OasisSelect'
import { useGetDailyRecs } from '../components/home/queries'
import OasisThemeForm from '../components/onboarding/OasisThemeForm'
import MinutesProgressBarProfile from '../components/profile/MinutesProgressBarProfile'
import ActivityCard from '../components/_shared/card/ActivityCard'
import FaIcon from '../components/_shared/FaIcon'
import FixedContainer from '../components/_shared/layout/FixedContainer'
import MinutesProgressBar from '../components/_shared/MinutesProgressBar'
import BottomSheet from '../components/_shared/notification/BottomSheet'
import { SkeletonLoader } from '../components/_shared/SkeletonLoader'
import secureScreen from '../hooks/authentication/secureScreen'
import DefaultLayout from '../layouts/DefaultLayout'
import { useFeatures } from '../providers/FeatureProvider'
import { useUserProfileContext } from '../providers/UserProfileProvider'
import { useGetLiveAndUpcoming } from '../queries/activitiesQueries'
import { useMemberLeaderboard } from '../queries/leaderboardQueries'

const styles = StyleSheet.create({
  bold: {
    fontFamily: 'Poppins_600SemiBold',
  },
})

const HomeScreen = ({ navigation }) => {
  const { userProfile, isFetched } = useUserProfileContext()
  const isMobile = useMobileBreakpoint()
  const isDesktop = useDesktopBreakpoint()
  const { height, width } = useWindowDimensions()
  const { liveAndUpcoming } = useGetLiveAndUpcoming()
  const { data, isLoading } = useMemberLeaderboard()
  const { features } = useFeatures()

  const expectedMinutes = userProfile?.mentalHealthMinutes * 7
  const minutes = userProfile?.healthMinutesProgress
  const oasis = userProfile?.oasis

  const [oasisModalShown, setOasisModalShown] = useState(false)
  const [minutesModalShown, setMinutesModalShown] = useState(false)
  const [mounted, setMounted] = useState(false)

  const { selectOasis } = useUserProfileContext()
  const { dailyRecs } = useGetDailyRecs()
  const [modalData, setModalData] = useState(null)
  const [modalShown, setModalShown] = useState(false)
  const handleCloseModal = () => setModalShown(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    if (Platform.OS === 'web' && userProfile.id) {
      BugSnagService.setUser(userProfile)
    }
  }, [userProfile])

  const onPressActivity = (activity) => {
    if (activity.type === ActivityType.VIBE_CHECK) {
      if (activity.recommendation === ActivityRecommendationState.COMPLETED)
        return navigation.navigate('VibeReview', {
          picked: activity.mood,
          comment: activity.note,
          date: activity.createdAt,
        })
      return navigation.navigate('VibeCheck')
    }

    setModalData(activity)
    setModalShown(true)
  }

  const onSelectOasis = (oasis) => {
    selectOasis(oasis)
    setOasisModalShown(false)
  }

  const checklistItems = dailyRecs?.map((activity) => (
    <Box maxW={isMobile ? '100%' : undefined}>
      <ActivityCard
        activity={activity}
        onPress={() => onPressActivity(activity)}
      />
    </Box>
  ))

  const checklistConditions = dailyRecs?.map(
    (activity) =>
      activity.recommendation === ActivityRecommendationState.COMPLETED
  )

  useEffect(() => {
    if (isFetched) {
      clevertap.registerForPush()
      analytics.track('Home Reached')
    }
  }, [isFetched])

  return (
    <DefaultLayout bg="muted.50" activeLabel="Today">
      <StatusBar barStyle="light-content" />
      <HomeImageBackground>
        <FixedContainer
          size="lg"
          props={{
            height: 112,
          }}>
          <HStack
            mt={Platform.OS === 'ios' ? '2' : '6'}
            mb={isMobile ? 'auto' : '6'}
            mx={isDesktop ? '0' : '4'}
            alignItems="center"
            w={isMobile ? width - 32 : undefined}
            justifyContent="space-between"
            safeAreaTop>
            <HStack alignItems="center">
              {isFetched && (
                <OasisSelect onPress={() => setOasisModalShown(true)} />
              )}
              <Heading color="white">Welcome</Heading>
            </HStack>
            <Box>
              {isFetched ? (
                <MinutesProgress
                  key={expectedMinutes}
                  doneMinutes={minutes}
                  expectedMinutes={expectedMinutes}
                  onPress={() => {
                    setMinutesModalShown(true)
                  }}
                />
              ) : (
                <SkeletonLoader size={{ width: '100%', height: 300 }} />
              )}
            </Box>
          </HStack>
        </FixedContainer>
        {!isMobile && (
          <HomeDesktopView
            isFetched={isFetched}
            upcomingGroups={liveAndUpcoming?.slice(0, 5)}
            checklistItems={checklistItems}
            checklistConditions={checklistConditions}
          />
        )}
      </HomeImageBackground>

      {isMobile && (
        <ScrollView
          h={height - 200}
          showsVerticalScrollIndicator={false}
          borderRadius="2xl"
          mt="112px"
          snapToOffsets={[0, 200]}>
          <HomeMobileView
            upcomingGroups={liveAndUpcoming?.slice(0, 5)}
            checklistItems={checklistItems}
            checklistConditions={checklistConditions}
          />
        </ScrollView>
      )}
      <BottomSheet
        body={<OasisThemeModal oasis={oasis} onSelectOasis={onSelectOasis} />}
        isOpen={oasisModalShown}
        title="Your Oasis"
        handleClose={() => setOasisModalShown(false)}
        hasCloseOnBottom={true}
      />
      <BottomSheet
        body={
          features.MHM_LEADERBOARD_POPUP ? (
            <VStack>
              <MinutesProgressBarProfile mt={0} headerHeight={300} />
              {userProfile?.healthMinutesProgress === 0 ? (
                <VStack mt={-12}>
                  <Text textAlign="center" color="primary.900" fontSize="16px">
                    Track your progress toward your weekly goal
                  </Text>
                  <Button
                    mt={7}
                    onPress={() => {
                      setMinutesModalShown(false)
                      navigation.navigate('Explore')
                    }}>
                    <Text color="white" fontSize="16px" fontWeight={600}>
                      Explore activities
                    </Text>
                  </Button>
                </VStack>
              ) : (
                <VStack mt={-12}>
                  <Text
                    mt={-4}
                    mb={3}
                    textAlign="center"
                    color="primary.900"
                    fontSize="20px"
                    fontWeight={600}>
                    Way to go!
                  </Text>
                  {!isLoading && (
                    <Text
                      width="70%"
                      alignSelf="center"
                      fontSize="16px"
                      color="primary.900"
                      textAlign="center">
                      <Text>{`You’re ranked `}</Text>
                      <Text>
                        <FaIcon
                          onPress={() => {}}
                          color="primary.700"
                          size={16}
                          isSolid
                          name="medal"
                        />
                      </Text>
                      <Text
                        style={styles.bold}>{` ${data.rankPosition} `}</Text>
                      <Text>{`in this month's challenge with `}</Text>
                      <Text>
                        <FaIcon
                          onPress={() => {}}
                          name="star"
                          color="yellow.500"
                          size={16}
                          isSolid
                        />
                      </Text>
                      <Text style={styles.bold}>{`${data.totalMinutes} `}</Text>
                      <Text>Mental Health Minutes so far</Text>
                    </Text>
                  )}
                  <Button
                    mt={7}
                    onPress={() => {
                      setMinutesModalShown(false)
                      navigation.navigate('Profile')
                    }}>
                    <Text color="white" fontSize="16px" fontWeight={600}>
                      View full Leaderboard
                    </Text>
                  </Button>
                </VStack>
              )}
              <Button
                onPress={() => setMinutesModalShown(false)}
                variant="unstyled">
                <Text fontWeight={500}>Close</Text>
              </Button>
            </VStack>
          ) : (
            <ModalMinutesProgress
              doneMinutes={minutes}
              expectedMinutes={expectedMinutes}
            />
          )
        }
        isMHM={features.MHM_LEADERBOARD_POPUP}
        hasDivider={!features.MHM_LEADERBOARD_POPUP}
        isOpen={minutesModalShown}
        title={
          features.MHM_LEADERBOARD_POPUP
            ? 'Weekly progress'
            : 'Mental health minutes'
        }
        handleClose={() => setMinutesModalShown(false)}
        hasCloseOnBottom={!features.MHM_LEADERBOARD_POPUP}
      />
      <ActivityModalPreview
        modalData={modalData}
        modalShown={modalShown}
        handleCloseModal={handleCloseModal}
        bottomSheetStyling={{
          hasDivider: false,
          width: 400,
          top: !isMobile ? '0px' : null,
          hasCloseOnBottom: false,
        }}
      />
    </DefaultLayout>
  )
}

const OasisThemeModal = ({ oasis, onSelectOasis }) => {
  return (
    <Box mt={'10px'} mb={'110px'} alignSelf="center">
      <OasisThemeForm value={oasis} setValue={onSelectOasis} isInModal />
    </Box>
  )
}

const ModalMinutesProgress = ({ doneMinutes, expectedMinutes }) => {
  return (
    <Box mt={'10px'} mb={'110px'} alignSelf="center">
      <MinutesProgressBar
        doneMinutes={doneMinutes}
        expectedMinutes={expectedMinutes}
      />
      <Center>
        <Text py={'8px'} fontWeight="bold" color="secondary.500" fontSize="lg">
          {doneMinutes}/{expectedMinutes} minutes
        </Text>
        <Text fontWeight="light" textAlign="center" maxW={270}>
          Track your progress and work toward your weekly goal!
        </Text>
      </Center>
    </Box>
  )
}

export default secureScreen(HomeScreen)
