import {
  Box,
  Divider,
  FlatList,
  HStack,
  Image,
  Pressable,
  Text,
  VStack,
} from 'native-base'
import { useState } from 'react'
import { Platform } from 'react-native'
import { LeaderboardInterface } from '../../../../../domain/models/interfaces/leaderboardInterfaces'
import { useMobileBreakpoint } from '../../../../../domain/services/useBreakpoint'
import useLinkHandler from '../../../hooks/useLinkHandler'
import DefaultModal from '../../modals/DefaultModal'
import { SkeletonLoader } from '../../_shared/SkeletonLoader'
import TextLink from '../../_shared/text/TextLink'
import ParticipantRow from './ParticipantRow'

const image = require('../../../assets/images/leaderboard/full-ranking.png')

interface LeaderboardProps {
  data: LeaderboardInterface
  type?: string
  isLoading: boolean
}

const Leaderboard = ({ data, type = 'all', isLoading }: LeaderboardProps) => {
  const record = !data
    ? {
        next: null,
        rank: [],
        self: null,
        previous: null,
        totalParticipants: 0,
      }
    : data
  const isMobile = useMobileBreakpoint()
  const top3 = !isLoading && [1, 2, 3].includes(record?.self?.rankPosition)

  const [showModal, setShowModal] = useState(false)

  const space = isMobile ? 4 : 6

  const GotoMonthlyChallenge = () =>
    useLinkHandler('https://campaign.yourcabana.com/challenges')

  return (
    <VStack
      mx={space}
      py={space}
      mb={type !== 'all' && space}
      bg="white"
      borderWidth={1}
      borderRadius="20px"
      borderColor="grey.200">
      {type === 'monthly' && (
        <Pressable onPress={GotoMonthlyChallenge}>
          <VStack
            bg="primary.50"
            justifyContent={'space-evenly'}
            alignItems={'center'}
            mx={space}
            mb={space}
            py={space / 2}
            h={'84px'}
            borderRadius={10}>
            <Text color="primary.600" fontWeight="bold" fontSize={16}>
              This month's challenge
            </Text>
            <Text color="primary.600">Learn more</Text>
          </VStack>
        </Pressable>
      )}
      <Text
        px={space}
        mb={2}
        color="primary.900"
        fontWeight={600}
        fontSize="16px">
        {type === 'all' ? 'All time' : 'This month'}
      </Text>
      {isLoading ? (
        <SkeletonLoader mb={6} size={{ width: '100%', height: '300px' }} />
      ) : (
        <>
          <FlatList
            showsHorizontalScrollIndicator={false}
            data={
              record.rank && record.rank.length > 0
                ? record.rank.slice(0, 3)
                : []
            }
            keyExtractor={(participant) => participant.memberId.toString()}
            renderItem={({ item: participant, index }) => (
              <>
                <ParticipantRow participant={participant} type={type} />
                {index === 2 && !top3 && (
                  <Divider width="95%" alignSelf="center" />
                )}
              </>
            )}
          />
          {!top3 && (
            <VStack>
              {record.previous && record.previous.rankPosition !== 3 && (
                <ParticipantRow type={type} participant={record.previous} />
              )}
              {record.self && (
                <ParticipantRow type={type} participant={record.self} />
              )}
              {record.next && (
                <ParticipantRow type={type} participant={record.next} />
              )}
            </VStack>
          )}
          <HStack
            px={space}
            mt={4}
            alignItems="center"
            justifyContent="space-between">
            {/* Change to totalParticipants */}
            <Text
              fontSize="12px"
              color="text.500">{`${record.totalParticipants} Total participants`}</Text>
            <TextLink
              customOnPress={() => {
                setShowModal(true)
              }}
              color="primary.900"
              fontWeight="500"
              fontSize="12px">
              Full rankings
            </TextLink>
          </HStack>
          <DefaultModal
            show={showModal}
            closeModal={() => setShowModal(false)}
            action={() => {}}
            height="460px"
            body={
              <Box mt={Platform.OS !== 'web' && -8} mb={-20}>
                <Text
                  fontSize="16px"
                  fontWeight={500}
                  color="primary.900"
                  pb="8px">
                  Leaderboard
                </Text>
                <VStack alignItems="center">
                  <Image
                    my={4}
                    style={{
                      height: isMobile && Platform.OS !== 'web' ? 200 : 160,
                      width: 260,
                    }}
                    source={image}
                    alt="squid image"
                  />
                  <Text fontSize="20px" fontWeight={600} color="primary.900">
                    Full ranking
                  </Text>
                  <Text
                    mt={3}
                    textAlign="center"
                    fontSize="16px"
                    color="primary.900"
                    pb="8px">
                    A view of the full leaderboard ranking is coming soon!
                  </Text>
                </VStack>
              </Box>
            }
            modalInfo={{
              buttonText: 'OK',
              h: 170,
              w: 220,
            }}
          />
        </>
      )}
    </VStack>
  )
}

export default Leaderboard
