import { withAuthenticationRequired } from '@auth0/auth0-react'

const secureScreen = (screen: React.ComponentType<object>) =>
  withAuthenticationRequired(screen, {
    loginOptions: {
      authorizationParams: {
        connection: 'email',
      },
    },
  })

export default secureScreen
